import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';

import emilogBackground from '../assets/compressedBG.png';
import emilogBackgroundDownscaled from '../assets/downscaledBG.png';
import emilogLogo from '../assets/logo.png';
import dsgvo from '../assets/dsgvo.png';

const useStyles = (loaded) => makeStyles((theme) => ({
  '@global': {
    html: {
      background: `url(${loaded ? emilogBackground : emilogBackgroundDownscaled}) no-repeat center center fixed`,
      backgroundSize: 'cover',
      backgroundPositionY: '100%',
      backgroundColor: '#ececec',
      [theme.breakpoints.down('sm')]: {
        backgroundPositionX: '0%',
      },
    },
    body: {
      background: 'none',
    },
  },
  paper: {
    padding: 20,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    marginTop: theme.spacing(8),
    borderRadius: 8,
    zIndex: 5,
    position: 'relative',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dsgvoLogo: {
    position: 'fixed',
    width: 166,
    bottom: 36,
    left: 64,
    [theme.breakpoints.down('sm')]: {
      width: 132,
      bottom: 30,
      left: 36,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 24,
      left: 10,
    },
  },
  emilogLogo: {
    position: 'fixed',
    width: 264,
    bottom: 36,
    right: 50,

    [theme.breakpoints.down('sm')]: {
      width: 158,
      bottom: 50,
      right: 32,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      width: 140,
      bottom: 46,
      right: 4,
    },
  },
  hinweis: {
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),

    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
  hinweisHeader: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
  highlightBlueBold: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
  },
  // container: {
  //   display: 'flex',
  // },
  logoWrapper: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      height: 120,
    },
  },
}));

export default function PublicLayout({ children }) {
  PublicLayout.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [loaded, setLoaded] = useState(false);
  const classes = useStyles(loaded)();
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <CssBaseline />
      <Card className={classes.card}>
        <div className={classes.paper}>
          {children}
        </div>
      </Card>
      <div className={classes.hinweis}>
        <div className={classes.hinweisHeader}>Achtung! Wichtiger Hinweis!</div>
        Seit dem
        {' '}
        <span className={classes.highlightBlueBold}>01.01.2022</span>
        {' '}
        ist es Pflicht während der akkreditierten Messung Ihr Messgerät durch das Messinstitut
        {' '}
        <span className={classes.highlightBlueBold}>auf Funktionalität prüfen</span>
        {' '}
        zu lassen. Für eine einfache und schnelle Bearbeitung ist es wichtig den
        {' '}
        <Link target="_blank" rel="noreferrer" className={classes.link} href="https://www.bhkwteile.de/emissionsmessung-oxid-1/kontinuierliche-ueberwachung/digitaler-messbericht-emi-log.html">digitalen Messbericht</Link>
        , Ihr Logbuch und das Katalysatordatenblatt bereitzuhalten. Die Angaben werden vom Messinstitut bei der Emissionsmessung überprüft und müssen deshalb zwingend vom Betreiber zur Verfügung gestellt werden.

      </div>
      <div className={classes.logoWrapper}>

        <img src={dsgvo} alt="DSGVO konform" className={classes.dsgvoLogo} />
        <img src={emilogLogo} alt="Emilog Logo" className={classes.emilogLogo} />
      </div>
    </Container>
  );
}
